<template>
  <admin>
    <metatag title="Quotations"></metatag>

    <page-header></page-header>

    <v-row>
      <v-col>
        <v-card>
          <toolbar-menu
            ref="toolbar"
            :items.sync="tabletoolbar"
            @update:search="setSearch"
          ></toolbar-menu>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="resources.headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :loading="loading"
              :items-per-page="5"
              color="primary"
              item-key="id"
              class="text-no-wrap"
            >
              <template v-slot:progress><span></span></template>

              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>

              <template v-slot:item.created_at="{ item }">
                <small>{{ item.created_at }}</small> <br />
              </template>

              <template v-slot:item.type="{ item }">
                <v-chip v-if="item.type" label>
                  {{ item.type }}
                </v-chip>
              </template>

              <template v-slot:item.message="{ item }">
                <div class="text-truncate">
                  <strong> {{ item.title }}</strong> <br />
                  <small>{{ item.message }}</small>
                </div>
              </template>

              <template v-slot:item.opened="{ item }">
                <v-chip
                  :color="item.opened ? 'green' : 'red'"
                  text-color="white"
                  class="mx-1"
                  label
                >
                  {{ item.opened ? "Open" : "Unopen" }}
                </v-chip>
              </template>

              <template v-slot:item.id="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="previewItem(item)"
                      text
                      icon
                      color="primary"
                      v-on="on"
                    >
                      {{ item.id }}
                    </v-btn>
                  </template>
                  <span v-text="'Preview'"></span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="previewDialog"
      persistent
      max-width="900px"
      class="overflow-hidden"
    >
      <v-card class="pa-4">
        <v-card-title>
          <h2 title="Edit" class="mb-1"># {{ selectedItem.id }}</h2>
        </v-card-title>
        <v-card-text class="overflow-y-auto">
          <!-- Background Details -->
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Title" }}</td>
                  <td>{{ selectedItem.title }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Message" }}</td>
                  <td>{{ selectedItem.message }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Type" }}</td>
                  <td>{{ selectedItem.type }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Link" }}</td>
                  <td>{{ selectedItem.link }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Opened" }}</td>
                  <td>
                    <v-chip
                      :color="selectedItem.opened ? 'green' : 'red'"
                      text-color="white"
                      class="mx-1"
                      label
                    >
                      {{ selectedItem.opened ? "Open" : "Unopen" }}
                    </v-chip>
                    {{
                      formatDate(selectedItem.opened_at, "D MMMM YYYY @ h:mm a")
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">{{ "Dealer" }}</td>
                  <td>{{ selectedItem.dealer }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">
                    {{ "Date Created" }}
                  </td>
                  <td>
                    {{
                      formatDate(
                        selectedItem.created_at,
                        "D MMMM YYYY @ h:mm a"
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-no-wrap font-weight-bold">
                    {{ "Date Updated" }}
                  </td>
                  <td>
                    {{
                      formatDate(
                        selectedItem.updated_at,
                        "D MMMM YYYY @ h:mm a"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- Background Details -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="grey"
            exact
            class="ma-1 white--text px-5"
            @click="closePreview"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      statusFilter: null,
      actionFilter: null,
      modelFilter: null,
      inquiryDialog: false,
      previewDialog: false,
      resources: {
        headers: [
          {
            text: "Message",
            align: "left",
            value: "message",
            class: "text-no-wrap",
          },
          { text: "Type", value: "type", class: "text-no-wrap" },
          { text: "Date", value: "created_at", class: "text-no-wrap" },
        ],
        data: [],
      },
      editedItem: {
        id: null,
        sales_consultant: null,
        status: null,
        action: null,
      },
      tabletoolbar: {
        isSearching: false,
        search: null,
        type: "quotation",
      },
      loading: true,
      options: {},
      items: [],
      total: 0,
      selectedItem: {},
      valid: true,
      loadingSave: false,
      loadingFileUpload: false,
      selectedFile: {},
      selectedId: null,
    };
  },
  components: {
    SalesConsultant,
  },
  computed: {
    ...mapGetters({
      data: "notifications/GET_NOTIFICATIONS",
    }),
  },
  async mounted() {},
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getNotifications: "notifications/getNotifications",
    }),
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        sort: "DESC",
        q,
      };
      this.getNotifications(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },

    previewItem(item) {
      this.previewDialog = true;
      this.selectedItem = item;
    },

    closePreview() {
      this.previewDialog = false;
      this.selectedItem = {};
    },
  },
};
</script>

<style></style>
